//Registration
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTRATION_ERROR = 'REGISTRATION_ERROR';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';

//Email Activation
export const ACTIVATING_EMAIL = 'ACTIVATING_EMAIL';
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
export const ACTIVATION_ERROR = 'ACTIVATION_ERROR';

//Login
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const UPDATE_LOGGED_IN_STATUS = 'UPDATE_LOGGED_IN_STATUS';
export const SET_REDIRECT_URL = 'SET_REDIRECT_URL';

//Candidates
export const GET_CANDIDATE_REFERENCES = 'GET_CANDIDATE_REFERENCES';
export const RECEIVED_CANDIDATE_REFERENCES = 'RECEIVED_CANDIDATE_REFERENCES';
export const GET_CANDIDATES = 'GET_CANDIDATES';
export const RECEIVED_CANDIDATES = 'RECEIVED_CANDIDATES';
export const GET_CANDIDATE = 'GET_CANDIDATE';
export const RECEIVED_CANDIDATE = 'RECEIVED_CANDIDATE';
export const SET_ACTIVE_REFERENCE = 'SET_ACTIVE_REFERENCE';
export const SET_CANCEL_REMINDER_LOADER = 'SET_CANCEL_REMINDER_LOADER';
export const SET_SEND_REMINDER_LOADER = 'SET_SEND_REMINDER_LOADER';
export const DELETE_CANDIDATE = 'DELETE_CANDIDATE';
export const CANDIDATE_DELETED = 'CANDIDATE_DELETED';
export const CANDIDATE_DELETE_FAILED = 'CANDIDATE_DELETE_FAILED';
export const GET_FILTER_DATA = 'GET_FILTER_DATA';
export const RECEIVED_FILTER_DATA = 'RECEIVED_FILTER_DATA';
export const FILTER_DATA_ERROR = 'FILTER_DATA_ERROR';

//Reference
export const POST_REFERENCE_REQUEST = 'POST_REFERENCE_REQUEST';
export const REFERENCE_REQUEST_SUCCESS = 'REFERENCE_REQUEST_SUCCESS';
export const REFERENCE_REQUEST_ERROR = 'REFERENCE_REQUEST_ERROR';
export const CLEAR_REFERENCE_REQUEST = 'CLEAR_REFERENCE_REQUEST';
export const SET_REMINDER_UPDATE = 'SET_REMINDER_UPDATE';
export const REMINDER_UPDATED = 'REMINDER_UPDATED';
export const REMINDER_UPDATE_FAILED = 'REMINDER_UPDATE_FAILED';
export const CLEAR_SUCCESS_REMINDER = 'CLEAR_SUCCESS_REMINDER';
export const DELETE_REFERENCE = 'DELETE_REFERENCE';
export const DELETE_REFERENCE_SUCCESS = 'DELETE_REFERENCE_SUCCESS';
export const DELETE_REFERENCE_FAILED = 'DELETE_REFERENCE_FAILED';
export const EDIT_NUM_OF_REFERENCES = 'EDIT_NUM_OF_REFERENCES';
export const EDIT_NUM_OF_REFERENCES_SUCCESS = 'EDIT_NUM_OF_REFERENCES_SUCCESS';
export const EDIT_NUM_OF_REFERENCES_FAILED = 'EDIT_NUM_OF_REFERENCES_FAILED';

//User
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const UPDATE_ORGANISATION_NAME = 'UPDATE_ORGANISATION_NAME';
export const UPDATING_ORGANISATION_NAME = 'UPDATING_ORGANISATION_NAME';
export const CLEAR_ORG_NAME_MESSAGE = 'CLEAR_ORG_NAME_MESSAGE';
export const GET_ACCOUNT_DETAILS = 'GET_ACCOUNT_DETAILS';
export const UPDATE_ACCOUNT_DETAILS = 'UPDATE_ACCOUNT_DETAILS';

//Logout
export const LOGGED_OUT = 'LOGGED_OUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

//Admin
export const GET_ADMIN_USERS = 'GET_ADMIN_USERS';
export const RECEIVED_ADMIN_USERS = 'RECEIVED_ADMIN_USERS';
export const ERROR_ADMIN_USERS = 'ERROR_ADMIN_USERS';
export const GET_TEAMS = 'GET_TEAMS';
export const ERROR_TEAMS = 'ERROR_TEAMS';
export const RECEIVED_TEAMS = 'RECEIVED_TEAMS';

export const CREATE_NEW_USER = 'CREATE_NEW_USER';
export const CREATED_NEW_USER = 'CREATED_NEW_USER';
export const ERROR_CREATING_NEW_USER = 'ERROR_CREATING_NEW_USER';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATED_USER = 'UPDATED_USER';
export const ERROR_UPDATING_USER = 'ERROR_UPDATING_USER';
export const DELETE_USER = 'DELETE_USER';
export const DELETED_USER = 'DELETED_USER';
export const ERROR_DELETING_USER = 'ERROR_DELETING_USER';

export const CREATE_NEW_TEAM = 'CREATE_NEW_TEAM';
export const CREATED_NEW_TEAM = 'CREATED_NEW_TEAM';
export const ERROR_CREATING_NEW_TEAM = 'ERROR_CREATING_NEW_TEAM';

export const UPDATE_TEAM = 'UPDATE_TEAM';
export const UPDATED_TEAM = 'UPDATED_TEAM';
export const ERROR_UPDATING_TEAM = 'ERROR_UPDATING_TEAM';
export const DELETE_TEAM = 'DELETE_TEAM';
export const DELETED_TEAM = 'DELETED_TEAM';
export const ERROR_DELETING_TEAM = 'ERROR_DELETING_TEAM';

//Window
export const WINDOW_RESIZE = 'WINDOW_RESIZE';
export const SET_DEVICE_TYPE = 'SET_DEVICE_TYPE';
export const NETWORK_ERROR = 'NETWORK_ERROR';
export const SET_ONLINE_STATUS = 'SET_ONLINE_STATUS';

//Profile Questions
export const GET_QUESTION_PROFILES = 'GET_QUESTION_PROFILES';
export const RECEIVED_QUESTION_PROFILES = 'RECEIVED_QUESTION_PROFILES';
export const ERROR_QUESTION_PROFILES = 'ERROR_QUESTION_PROFILES';
export const GET_QUESTION_PROFILE = 'GET_QUESTION_PROFILE';
export const RECEIVED_QUESTION_PROFILE = 'RECEIVED_QUESTION_PROFILE';
export const ERROR_QUESTION_PROFILE = 'ERROR_QUESTION_PROFILE';
export const GET_QUESTION_PROFILE_SETTINGS = 'GET_QUESTION_PROFILE_SETTINGS';
export const RECEIVED_QUESTION_PROFILE_SETTINGS = 'RECEIVED_QUESTION_PROFILE_SETTINGS';
export const ERROR_QUESTION_PROFILE_SETTINGS = 'ERROR_QUESTION_PROFILE_SETTINGS';
export const UPDATE_QUESTION_PROFILE_SETTINGS = 'UPDATE_QUESTION_PROFILE_SETTINGS';
export const UPDATED_QUESTION_PROFILE_SETTINGS = 'UPDATED_QUESTION_PROFILE_SETTINGS';
export const ERROR_UPDATING_QUESTION_PROFILE_SETTINGS = 'ERROR_UPDATING_QUESTION_PROFILE_SETTINGS';
export const ADD_NEW_PROFILE = 'ADD_NEW_PROFILE';
export const NEW_PROFILE_SAVED = 'NEW_PROFILE_SAVED';
export const ERROR_SAVING_PROFILE = 'ERROR_SAVING_PROFILE';
export const SET_CREATE_PROFILE = 'SET_CREATE_PROFILE';
export const DELETE_PROFILE = 'DELETE_PROFILE';
export const PROFILE_DELETED = 'PROFILE_DELETED';
export const ERROR_DELETING_PROFILE = 'ERROR_DELETING_PROFILE';
export const CLEAR_DELETED_PROFILE_INFO = 'CLEAR_DELETED_PROFILE_INFO';
export const GET_QUESTION_PROFILE_PREVIEW = 'GET_QUESTION_PROFILE_PREVIEW';
export const RECEIVED_QUESTION_PROFILE_PREVIEW = 'RECEIVED_QUESTION_PROFILE_PREVIEW';
export const ERROR_QUESTION_PROFILE_PREVIEW = 'ERROR_QUESTION_PROFILE_PREVIEW';
export const SET_QUESTION_PROFILE_REFERENCE_TYPE = 'SET_QUESTION_PROFILE_REFERENCE_TYPE';
export const SET_QUESTION_PROFILE_LANGUAGE = 'SET_QUESTION_PROFILE_LANGUAGE';
export const CLONE_PROFILE = 'CLONE_PROFILE';
export const PROFILE_CLONED = 'PROFILE_CLONED';
export const ERROR_CLONING_PROFILE = 'ERROR_CLONING_PROFILE';

// Questions
export const ADD_NEW_QUESTION = 'ADD_NEW_QUESTION';
export const CREATED_NEW_QUESTION = 'CREATED_NEW_QUESTION';
export const ERROR_CREATING_NEW_QUESTION = 'ERROR_CREATING_NEW_QUESTION';
export const DELETE_QUESTION = 'DELETE_QUESTION';
export const DELETED_QUESTION = 'DELETED_QUESTION';
export const ERROR_DELETING_QUESTION = 'ERROR_DELETING_QUESTION';
export const SET_CREATE_QUESTION = 'SET_CREATE_QUESTION';
export const GET_PROFILE_LIST = 'GET_PROFILE_LIST';
export const RECEIVED_PROFILE_LIST = 'RECEIVED_PROFILE_LIST';
export const ERROR_RECEIVING_PROFILE_LIST = 'ERROR_RECEIVING_PROFILE_LIST';
export const UPDATE_QUESTIONS_ORDER = 'UPDATE_QUESTIONS_ORDER';
export const RESET_QUESTIONS_PROFILE_STATE = 'RESET_QUESTIONS_PROFILE_STATE';

//Locales
export const SET_LOCALE = 'SET_LOCALE';

//TalentPool
export const GET_TALENT_POOL_LIST = 'GET_TALENT_POOL_LIST';
export const RECEIVED_TALENT_POOL_LIST = 'RECEIVED_TALENT_POOL_LIST';
export const TALENT_POOL_LIST_ERROR = 'TALENT_POOL_LIST_ERROR';

//VacancyPool
export const GET_VACANCY_POOL_LIST = 'GET_VACANCY_POOL_LIST';
export const RECEIVED_VACANCY_POOL_LIST = 'RECEIVED_VACANCY_POOL_LIST';
export const VACANCY_POOL_LIST_ERROR = 'VACANCY_POOL_LIST_ERROR';

//Settings
export const LOADING_SETTINGS = 'LOADING_SETTINGS';
export const RECEIVED_SETTINGS = 'RECEIVED_SETTINGS';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';

//Tours
export const UPDATE_TOUR_STATUS = 'UPDATE_TOUR_STATUS';

//Subscription
export const RECEIVED_SUBSCRIPTION_DETAILS = 'RECEIVED_SUBSCRIPTION_DETAILS';
export const SUBMITTING_SUBSCRIPTION = 'SUBMITTING_SUBSCRIPTION';
export const REVIEW_SUBSCRIPTION_CHANGES = 'REVIEW_SUBSCRIPTION_CHANGES';
export const CLEAR_SUBSCRIPTION_REVIEW = 'CLEAR_SUBSCRIPTION_REVIEW';
export const SUBSCRIPTION_UPDATED = 'SUBSCRIPTION_UPDATED';
export const UPDATE_CREDIT_BALANCE = 'UPDATE_CREDIT_BALANCE';

//Top-Up
export const RECEIVED_TOP_UP_DETAILS = 'RECEIVED_TOP_UP_DETAILS';
export const TOP_UP_PAYMENT = 'TOP_UP_PAYMENT';

//Inbound
export const LOADING_INBOUND_STATS = 'LOADING_INBOUND_STATS';
export const RECEIVED_INBOUND_STATS = 'RECEIVED_INBOUND_STATS';

//Referencing Dashboard
export const LOADING_REFERENCING_STATS = 'LOADING_REFERENCING_STATS';
export const RECEIVED_REFERENCING_STATS = 'RECEIVED_REFERENCING_STATS';
